/* eslint-disable no-mixed-spaces-and-tabs */
const color = {
    white: '#fff',
    black: '#000',

    slate: {
        slate900: '#0f172a',
        slate500: '#64748B',
        slate300: '#cbd5e1',
        slate100: '#F1F5F9',
    },
    sky: {
        sky500: '#0EA5E9',
        sky200: '#BAE6FD',
        sky100: '#E0F2FE',
        sky50: '#F0F9FF',
    },
    blue: {
        blue600: '#2563eb',
        blue50: '#eff6ff',
    },
    green: {
        green600: '#16A34A',
        green50: '#F0FDF4',
    },
    emerald: {
        emerald500: '#10B981',
        emerald50: '#ECFDF5' 
    },
    rose: {
        rose600: '#E11D48',
        rose500: '#F43F5E',
        rose50: '#FEF1F2',
    },
    amber: {
        amber500: '#f59e0b',
        amber100: '#FEF3C7',
        amber50: '#fffbeb',
    },
}

export default color

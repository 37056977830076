import axiosUtil from '../utils/axiosUtil';
import { GetSplitwithdrawalResult } from '../apiIntegration/models/withdrawal/getWithdrawal';

// Example GET request with token
export function getSplitwithdrawal(token: string) {
    return axiosUtil.get<any>('/splitwithdrawal/landing', {}, {}, token)
    .then(response => {
        if(response.status === 200) {
            return response.data as GetSplitwithdrawalResult;
        }
    });
};

export function confirmSplitwithdrawal(splitWithdrawalOrderId: string, token: string) {
    return axiosUtil.put<any>(`/splitwithdrawal/confirm`, {splitWithdrawalOrderId: splitWithdrawalOrderId}, {}, token)
    .then(response => {
        return response;
    });
};

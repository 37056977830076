import axios, { AxiosRequestConfig, AxiosResponse } from 'axios';
import * as ConfigUtil from '../utils/configUtil';

// Define the Axios instance with default configurations
const axiosInstance = axios.create({
  baseURL: ConfigUtil.getRodimusCashierWebApiBaseUrl() + '/api', // Replace with your API's base URL
  timeout: 60000, // Set a timeout for requests
  headers: {
    'Content-Type': 'application/json',
  },
});

interface ApiResponse<T> {
  data: T;
  status: number;
  error?: string; // Optional field for error messages
}

// Utility functions for API calls
const axiosUtil = {
  get: async <T>(url: string, params: object = {}, config: AxiosRequestConfig = {}, token: string | null = null): Promise<ApiResponse<T>> => {
    try {
      if (token) {
        config.headers = { ...config.headers, Authorization: `Bearer ${token}` };
      }
      const response: AxiosResponse<T> = await axiosInstance.get(url, { params, ...config });
      return { data: response.data, status: response.status };
    } catch (error) {
      console.error('GET request error:', error);
      return { data: {} as T, status: 500, error: 'An error occurred' };
    }
  },

  post: async <T>(url: string, data: object, config: AxiosRequestConfig = {}, token: string | null = null): Promise<ApiResponse<T>> => {
    try {
      if (token) {
        config.headers = { ...config.headers, Authorization: `Bearer ${token}` };
      }
      const response: AxiosResponse<T> = await axiosInstance.post(url, data, config);
      return { data: response.data, status: response.status };
    } catch (error) {
      console.error('POST request error:', error);
      return { data: {} as T, status: 500, error: 'An error occurred' };
    }
  },

  put: async <T>(url: string, data: object, config: AxiosRequestConfig = {}, token: string | null = null): Promise<ApiResponse<T>> => {
    try {
      if (token) {
        config.headers = { ...config.headers, Authorization: `Bearer ${token}` };
      }
      const response: AxiosResponse<T> = await axiosInstance.put(url, data, config);
      if(response.status === 200) {
        return { data: response.data, status: response.status };
      } else {
        return { data: response.data, status: response.status };
      }
    } catch (error) {
      console.error('PUT request error:', error);
      return { data: {} as T, status: 500, error: 'An error occurred' };
    }
  },

  delete: async <T>(url: string, config: AxiosRequestConfig = {}, token: string | null = null): Promise<ApiResponse<T>> => {
    try {
      if (token) {
        config.headers = { ...config.headers, Authorization: `Bearer ${token}` };
      }
      const response: AxiosResponse<T> = await axiosInstance.delete(url, config);
      return { data: response.data, status: response.status };
    } catch (error) {
      console.error('DELETE request error:', error);
      return { data: {} as T, status: 500, error: 'An error occurred' };
    }
  },
};

export default axiosUtil;

export function formatNumberWithCommas(amount: number): string {
  return amount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

export function formatDate(inputDate: string): string {
  const date = new Date(inputDate);

  const options: Intl.DateTimeFormatOptions = {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
    hour: '2-digit',
    minute: '2-digit',
    hour12: false,
  };

  const formattedDate = date.toLocaleString('en-GB', options);
  
  // Format the string as 'YYYY/MM/DD HH:MM'
  const [datePart, timePart] = formattedDate.split(', ');
  const formattedDatePart = datePart.split('/').reverse().join('/');

  return `${formattedDatePart} ${timePart}`;
};

export function getCurrencySymbol(currencyCode: string): string {
  try {
    // Manually handle specific cases for well-known currencies
    const currencySymbols: { [key: string]: string } = {
      'CNY': '¥',
      'THB': '฿',
      'IDR': 'Rp',
      // Add other specific cases here if needed
    };

    // Check if the currencyCode is in our manual mapping
    if (currencySymbols[currencyCode]) {
      return currencySymbols[currencyCode];
    }
    // Create an instance of Intl.NumberFormat for the given currency code
    const numberFormat = new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: currencyCode,
    });

    // Extract the currency symbol from the formatted number
    const parts = numberFormat.formatToParts(0);
    const currencyPart = parts.find(part => part.type === 'currency');

    // Return the currency symbol or a default value if not found
    return currencyPart ? currencyPart.value : 'Unknown currency';
  } catch (error) {
    console.error('Error getting currency symbol:', error);
    return 'Unknown currency';
  }
};